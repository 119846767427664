import { render, staticRenderFns } from "./ActionsTable.vue?vue&type=template&id=0cc7f53b&scoped=true"
import script from "./ActionsTable.vue?vue&type=script&lang=ts"
export * from "./ActionsTable.vue?vue&type=script&lang=ts"
import style0 from "./ActionsTable.vue?vue&type=style&index=0&id=0cc7f53b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc7f53b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactGoalActionInsight: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/ActionInsight.vue').default,ImpactGoalEvidenceCardListItem: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/EvidenceCardListItem.vue').default,IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VCol,VCombobox,VDataTable,VIcon,VList,VListItem,VRow})
