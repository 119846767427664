import { renderMarkdown } from '~/functions/markdown';
import { getClickableLink } from '~/functions/helpers';
export default defineComponent({
  props: {
    insight: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      hovering: false
    };
  },
  methods: {
    openDialog: function openDialog() {
      this.dialogVisible = true;
    },
    rateInsight: function rateInsight(rating, insightId) {
      // Propagate the rating to the parent component
      console.log('emitting the rate insight', rating, insightId);
      this.$emit('rate-insight', {
        rating: rating,
        insightId: insightId
      });
    },
    getClickableLink: getClickableLink,
    renderMarkdown: renderMarkdown
  }
});